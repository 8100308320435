/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VocPriorityEnum {
    Low = 'LOW',
    Middle = 'MIDDLE',
    High = 'HIGH'
}

export function VocPriorityEnumFromJSON(json: any): VocPriorityEnum {
    return VocPriorityEnumFromJSONTyped(json, false);
}

export function VocPriorityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VocPriorityEnum {
    return json as VocPriorityEnum;
}

export function VocPriorityEnumToJSON(value?: VocPriorityEnum | null): any {
    return value as any;
}

