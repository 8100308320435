import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { AccountCircle } from '@/src/components/foundations/DesignToken/Icons';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { Popover } from '@/src/components/foundations/Utils/Popover';
import { useAccount } from '@/src/hooks/useAccount';

import styles from './styles.module.scss';
import { PresenterProps } from './types';

import { Logout } from '@mui/icons-material';
import Dehaze from '@mui/icons-material/Dehaze';

export const NavigationPresenter: React.FC<PresenterProps> = (props) => {
  const { account } = useAccount();

  return (
    <nav className={styles.base}>
      <div className={styles.menuItem}>
        <button className={styles.menuButton} onClick={props.onClickMenuButton}>
          <Dehaze className={styles.menuIcon} />
        </button>
      </div>
      <p className={styles.title}>{props.title}</p>
      <Popover
        trigger={
          <button className={styles.account} onClick={props.showPopover}>
            <AccountCircle size={24} />
            <span className={styles.accountName}>{props.name}</span>
          </button>
        }
        placement="right"
        isShown={props.isShownPopover}
        hide={props.hidePopover}
      >
        <Card>
          <Section padding={16} radius={4}>
            <Stack align="stretch" gap={16}>
              <button
                className={styles.listItem}
                onClick={props.onClickGoToProfileNotification}
              >
                ユーザー設定
              </button>
              {/* テナント設定はCS権限の場合のみ利用可能 */}
              {account.csAccount && (
                <button
                  className={styles.listItem}
                  onClick={props.onClickGoToTenantSettings}
                >
                  テナント設定
                </button>
              )}
              <Divider />
              <button
                className={styles.listItem}
                onClick={props.onClickLogoutButton}
              >
                <Cluster align="center" gap="8px">
                  <Logout />
                  ログアウト
                </Cluster>
              </button>
            </Stack>
          </Section>
        </Card>
      </Popover>
    </nav>
  );
};
