/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 値の型
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionReportItemDefinitionValueTypeEnum {
    String = 'string',
    StringArray = 'string_array',
    Number = 'number',
    NumberArray = 'number_array'
}

export function MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSON(json: any): MedicalInstitutionReportItemDefinitionValueTypeEnum {
    return MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemDefinitionValueTypeEnum {
    return json as MedicalInstitutionReportItemDefinitionValueTypeEnum;
}

export function MedicalInstitutionReportItemDefinitionValueTypeEnumToJSON(value?: MedicalInstitutionReportItemDefinitionValueTypeEnum | null): any {
    return value as any;
}

