import { create } from 'zustand';

type Severity = 'error' | 'success';

type ToastState =
  | { isShown: false }
  | {
      isShown: true;
      severity: Severity;
      message: string;
    };

const toastStore = create<{
  toast: ToastState;
  showToast: (severity: Severity, message: string) => void;
  hideToast: VoidFunction;
}>((set) => ({
  toast: { isShown: false },
  showToast: (severity: Severity, message: string) => {
    set({ toast: { isShown: true, severity, message } });
    setTimeout(() => set({ toast: { isShown: false } }), 3000);
  },
  hideToast: () => set({ toast: { isShown: false } }),
}));

export const useToast = (): {
  toast: ToastState;
  showToast: (severity: Severity, message: string) => void;
  hideToast: VoidFunction;
} => {
  const { toast, showToast, hideToast } = toastStore();

  return {
    toast,
    showToast,
    hideToast,
  };
};
