import {
  CurrentUserTenant,
  CurrentUserRoleEnum,
  CurrentUserFeaturesEnum,
} from '@/src/api/generated';

import { create } from 'zustand';

export type AccountState = {
  id: number | null;
  name: string;
  email: string | null;
  loginName: string | null;
  csAccount: boolean | null;
  tenant: CurrentUserTenant | null;
  role: CurrentUserRoleEnum | null;
  features: CurrentUserFeaturesEnum[];
  ownDepartmentId: number | null;
  defaultFacilityTypeIds: number[] | null;
  emailSenderAllowedAddresses: string[];
};

const accountStore = create<{
  account: AccountState;
  setAccount: (account: AccountState) => void;
  clearAccount: VoidFunction;
}>((set) => {
  const defaultAccount: AccountState = {
    id: null,
    name: '',
    email: null,
    loginName: null,
    csAccount: false,
    tenant: null,
    role: null,
    features: [],
    ownDepartmentId: null,
    defaultFacilityTypeIds: null,
    emailSenderAllowedAddresses: [],
  };
  return {
    account: defaultAccount,
    setAccount: (account) => set({ account }),
    clearAccount: () => set({ account: defaultAccount }),
  };
});

export const useAccount = (): {
  account: AccountState;
  setAccount: (account: AccountState) => void;
  clearAccount: VoidFunction;
} => {
  const { account, setAccount, clearAccount } = accountStore();

  return {
    account,
    setAccount,
    clearAccount,
  };
};
