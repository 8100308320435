import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Img } from '@/src/components/foundations/DataDisplays/Img';
import { AccountCircle } from '@/src/components/foundations/DesignToken/Icons';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { Link } from '@/src/components/foundations/Utils/Link';
import { Popover } from '@/src/components/foundations/Utils/Popover';
import { useAccount } from '@/src/hooks/useAccount';

import styles from './spStyles.module.scss';
import { PresenterProps } from './types';

import { Logout } from '@mui/icons-material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Dehaze from '@mui/icons-material/Dehaze';
import clsx from 'clsx';

export const SpNavigationPresenter: React.FC<PresenterProps> = (props) => {
  const { account } = useAccount();

  return (
    <>
      <nav className={styles.navigation}>
        <button className={styles.menuButton} onClick={props.onClickMenuButton}>
          <Dehaze className={styles.menuIcon} />
        </button>
        <Link href="/medical_institutions_search">
          <Img className={styles.logo} alt="logo" src="/logo.png" />
        </Link>
        <Popover
          trigger={
            <button className={styles.account} onClick={props.showPopover}>
              <AccountCircle size={24} />
            </button>
          }
          placement="right"
          isShown={props.isShownPopover}
          hide={props.hidePopover}
        >
          <Card>
            <Section padding="8px 12px" radius={4}>
              <Stack align="stretch" gap={4}>
                <button
                  className={clsx([styles.listItem, styles.accountName])}
                  onClick={() => {}}
                >
                  <Cluster align="center" gap="8px" wrap="nowrap">
                    <AccountCircle size={24} />
                    {props.name}
                  </Cluster>
                </button>
                <Divider />
                <button
                  className={styles.listItem}
                  onClick={props.onClickGoToProfileNotification}
                >
                  ユーザー設定
                </button>
                {/* テナント設定はCS権限の場合のみ利用可能 */}
                {account.csAccount && (
                  <button
                    className={styles.listItem}
                    onClick={props.onClickGoToTenantSettings}
                  >
                    テナント設定
                  </button>
                )}
                <Divider />
                <button
                  className={styles.listItem}
                  onClick={props.onClickLogoutButton}
                >
                  <Cluster align="center" gap="8px">
                    <Logout />
                    ログアウト
                  </Cluster>
                </button>
              </Stack>
            </Section>
          </Card>
        </Popover>
      </nav>
      <div className={styles.titleWrapper}>
        {props.backLinkHref && (
          <Link href={props.backLinkHref} className={styles.backLink}>
            <ChevronLeft />
          </Link>
        )}
        <div className={styles.title}>{props.title}</div>
      </div>
    </>
  );
};
