// eslint-disable-next-line no-restricted-imports
import styles from './styles.module.scss';
import { Props } from './types';

import { SvgIcon } from '@mui/material';
import clsx from 'clsx';

export const DataCheck: React.FC<Props> = (props) => (
  <SvgIcon
    className={clsx(props.className, props.color ? styles[props.color] : null)}
    onClick={props.onClick}
    style={{
      height: props.size ? `${props.size}px` : undefined,
      width: props.size ? `${props.size}px` : undefined,
      cursor: props.cursor,
    }}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6667 9.10294L21.5833 5.13529L20.8056 4.35294L17.6667 7.51029L16.0833 5.91765L15.3056 6.72794L17.6667 9.10294ZM4 20V17.7647H17.3333V20H4ZM18.4444 12.1765C16.9074 12.1765 15.5972 11.6316 14.5139 10.5419C13.4306 9.45221 12.8889 8.13431 12.8889 6.58824C12.8889 5.04216 13.4306 3.72426 14.5139 2.63456C15.5972 1.54485 16.9074 1 18.4444 1C19.9815 1 21.2917 1.54485 22.375 2.63456C23.4583 3.72426 24 5.04216 24 6.58824C24 8.13431 23.4583 9.45221 22.375 10.5419C21.2917 11.6316 19.9815 12.1765 18.4444 12.1765ZM4 11.0588V8.82353H11C11.1296 9.23333 11.2778 9.62451 11.4444 9.99706C11.6111 10.3696 11.8148 10.7235 12.0556 11.0588H4ZM4 15.5294V13.2941H14.4444C14.8704 13.5549 15.3241 13.7738 15.8056 13.9507C16.287 14.1277 16.7963 14.2534 17.3333 14.3279V15.5294H4Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
