/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableDiagnosis,
    NullableDiagnosisFromJSON,
    NullableDiagnosisFromJSONTyped,
    NullableDiagnosisToJSON,
} from './NullableDiagnosis';

/**
 * 1紹介に対する入院(手術含む)の詳細
 * @export
 * @interface HospitalAdmission
 */
export interface HospitalAdmission {
    /**
     * 入院日
     * @type {string}
     * @memberof HospitalAdmission
     */
    admissionStartedOn: string | null;
    /**
     * 退院日
     * @type {string}
     * @memberof HospitalAdmission
     */
    admissionEndedOn: string | null;
    /**
     * 入院診療科(厚労省様式診療科/病院ごとに拡張された診療科)
     * @type {string}
     * @memberof HospitalAdmission
     */
    admissionDepartment: string | null;
    /**
     * 主傷病コード
     * @type {string}
     * @memberof HospitalAdmission
     */
    icd10MainDiseaseCode: string | null;
    /**
     * 主傷病名
     * @type {string}
     * @memberof HospitalAdmission
     */
    icd10MainDiseaseName: string | null;
    /**
     * 手術コード
     * @type {string}
     * @memberof HospitalAdmission
     */
    kcodeOperation1: string | null;
    /**
     * 手術名
     * @type {string}
     * @memberof HospitalAdmission
     */
    kcodeOperation1Name: string | null;
    /**
     * 手術日
     * @type {string}
     * @memberof HospitalAdmission
     */
    kcodeOperation1Date: string | null;
    /**
     * DPCコード
     * @type {string}
     * @memberof HospitalAdmission
     */
    dpcCode: string | null;
    /**
     * 
     * @type {NullableDiagnosis}
     * @memberof HospitalAdmission
     */
    dpcDiagnosis: NullableDiagnosis | null;
    /**
     * 点数
     * @type {number}
     * @memberof HospitalAdmission
     */
    totalScore?: number | null;
    /**
     * 手術点数
     * @type {number}
     * @memberof HospitalAdmission
     */
    totalOperationScore?: number | null;
    /**
     * 麻酔
     * @type {string}
     * @memberof HospitalAdmission
     */
    operation1AnesthesiaType: string | null;
    /**
     * 入院経路
     * @type {string}
     * @memberof HospitalAdmission
     */
    admissionRouteCode: string | null;
    /**
     * 予定・救急医療入院
     * @type {string}
     * @memberof HospitalAdmission
     */
    scheduledOrEmergencyCode: string | null;
    /**
     * 救急車による搬送の有無
     * @type {string}
     * @memberof HospitalAdmission
     */
    ambulanceTransportCode: string | null;
    /**
     * 入院前の在宅医療の有無
     * @type {string}
     * @memberof HospitalAdmission
     */
    beforeHomeMedicalCareCode: string | null;
    /**
     * 退院後の在宅医療の有無
     * @type {string}
     * @memberof HospitalAdmission
     */
    afterHomeMedicalCareCode: string | null;
    /**
     * 退院先
     * @type {string}
     * @memberof HospitalAdmission
     */
    dischargeToCode: string | null;
    /**
     * 退院時転帰
     * @type {string}
     * @memberof HospitalAdmission
     */
    dischargeOutcomeCode: string | null;
    /**
     * 入院中の主な診療目的
     * @type {string}
     * @memberof HospitalAdmission
     */
    purposeOfAdmissionCode: string | null;
}

export function HospitalAdmissionFromJSON(json: any): HospitalAdmission {
    return HospitalAdmissionFromJSONTyped(json, false);
}

export function HospitalAdmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalAdmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admissionStartedOn': json['admission_started_on'],
        'admissionEndedOn': json['admission_ended_on'],
        'admissionDepartment': json['admission_department'],
        'icd10MainDiseaseCode': json['icd10_main_disease_code'],
        'icd10MainDiseaseName': json['icd10_main_disease_name'],
        'kcodeOperation1': json['kcode_operation1'],
        'kcodeOperation1Name': json['kcode_operation1_name'],
        'kcodeOperation1Date': json['kcode_operation_1_date'],
        'dpcCode': json['dpc_code'],
        'dpcDiagnosis': NullableDiagnosisFromJSON(json['dpc_diagnosis']),
        'totalScore': !exists(json, 'total_score') ? undefined : json['total_score'],
        'totalOperationScore': !exists(json, 'total_operation_score') ? undefined : json['total_operation_score'],
        'operation1AnesthesiaType': json['operation_1_anesthesia_type'],
        'admissionRouteCode': json['admission_route_code'],
        'scheduledOrEmergencyCode': json['scheduled_or_emergency_code'],
        'ambulanceTransportCode': json['ambulance_transport_code'],
        'beforeHomeMedicalCareCode': json['before_home_medical_care_code'],
        'afterHomeMedicalCareCode': json['after_home_medical_care_code'],
        'dischargeToCode': json['discharge_to_code'],
        'dischargeOutcomeCode': json['discharge_outcome_code'],
        'purposeOfAdmissionCode': json['purpose_of_admission_code'],
    };
}

export function HospitalAdmissionToJSON(value?: HospitalAdmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admission_started_on': value.admissionStartedOn,
        'admission_ended_on': value.admissionEndedOn,
        'admission_department': value.admissionDepartment,
        'icd10_main_disease_code': value.icd10MainDiseaseCode,
        'icd10_main_disease_name': value.icd10MainDiseaseName,
        'kcode_operation1': value.kcodeOperation1,
        'kcode_operation1_name': value.kcodeOperation1Name,
        'kcode_operation_1_date': value.kcodeOperation1Date,
        'dpc_code': value.dpcCode,
        'dpc_diagnosis': NullableDiagnosisToJSON(value.dpcDiagnosis),
        'total_score': value.totalScore,
        'total_operation_score': value.totalOperationScore,
        'operation_1_anesthesia_type': value.operation1AnesthesiaType,
        'admission_route_code': value.admissionRouteCode,
        'scheduled_or_emergency_code': value.scheduledOrEmergencyCode,
        'ambulance_transport_code': value.ambulanceTransportCode,
        'before_home_medical_care_code': value.beforeHomeMedicalCareCode,
        'after_home_medical_care_code': value.afterHomeMedicalCareCode,
        'discharge_to_code': value.dischargeToCode,
        'discharge_outcome_code': value.dischargeOutcomeCode,
        'purpose_of_admission_code': value.purposeOfAdmissionCode,
    };
}

