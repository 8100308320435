/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostVocRequest,
    PostVocRequestFromJSON,
    PostVocRequestToJSON,
    PutVocRequest,
    PutVocRequestFromJSON,
    PutVocRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
    Voc,
    VocFromJSON,
    VocToJSON,
} from '../models';

export interface DeleteVocRequest {
    id: string;
}

export interface PostVocOperationRequest {
    postVocRequest: PostVocRequest;
}

export interface PutVocOperationRequest {
    id: string;
    putVocRequest: PutVocRequest;
}

/**
 * VocsApi - interface
 * 
 * @export
 * @interface VocsApiInterface
 */
export interface VocsApiInterface {
    /**
     * 
     * @summary 連携先フィードバックの削除
     * @param {string} id 連携先フィードバック id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VocsApiInterface
     */
    deleteVocRaw(requestParameters: DeleteVocRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 連携先フィードバックの削除
     */
    deleteVoc(requestParameters: DeleteVocRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary 連携先フィードバックの作成
     * @param {PostVocRequest} postVocRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VocsApiInterface
     */
    postVocRaw(requestParameters: PostVocOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Voc>>;

    /**
     * 連携先フィードバックの作成
     */
    postVoc(requestParameters: PostVocOperationRequest, initOverrides?: RequestInit): Promise<Voc>;

    /**
     * 
     * @summary 連携先フィードバックの更新
     * @param {string} id 連携先フィードバック id
     * @param {PutVocRequest} putVocRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VocsApiInterface
     */
    putVocRaw(requestParameters: PutVocOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Voc>>;

    /**
     * 連携先フィードバックの更新
     */
    putVoc(requestParameters: PutVocOperationRequest, initOverrides?: RequestInit): Promise<Voc>;

}

/**
 * 
 */
export class VocsApi extends runtime.BaseAPI implements VocsApiInterface {

    /**
     * 連携先フィードバックの削除
     */
    async deleteVocRaw(requestParameters: DeleteVocRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteVoc.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vocs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 連携先フィードバックの削除
     */
    async deleteVoc(requestParameters: DeleteVocRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteVocRaw(requestParameters, initOverrides);
    }

    /**
     * 連携先フィードバックの作成
     */
    async postVocRaw(requestParameters: PostVocOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Voc>> {
        if (requestParameters.postVocRequest === null || requestParameters.postVocRequest === undefined) {
            throw new runtime.RequiredError('postVocRequest','Required parameter requestParameters.postVocRequest was null or undefined when calling postVoc.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vocs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostVocRequestToJSON(requestParameters.postVocRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VocFromJSON(jsonValue));
    }

    /**
     * 連携先フィードバックの作成
     */
    async postVoc(requestParameters: PostVocOperationRequest, initOverrides?: RequestInit): Promise<Voc> {
        const response = await this.postVocRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先フィードバックの更新
     */
    async putVocRaw(requestParameters: PutVocOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Voc>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putVoc.');
        }

        if (requestParameters.putVocRequest === null || requestParameters.putVocRequest === undefined) {
            throw new runtime.RequiredError('putVocRequest','Required parameter requestParameters.putVocRequest was null or undefined when calling putVoc.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vocs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutVocRequestToJSON(requestParameters.putVocRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VocFromJSON(jsonValue));
    }

    /**
     * 連携先フィードバックの更新
     */
    async putVoc(requestParameters: PutVocOperationRequest, initOverrides?: RequestInit): Promise<Voc> {
        const response = await this.putVocRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
