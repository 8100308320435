import { useToast } from '@/src/hooks/useToast';

import { ToastPresenter } from './presenter';

export const Toast: React.FC = () => {
  const { toast, hideToast } = useToast();

  if (!toast.isShown) {
    return null;
  }

  return (
    <ToastPresenter
      severity={toast.severity}
      message={toast.message}
      onClickHideButton={hideToast}
    />
  );
};
