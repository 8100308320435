/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationComment,
    CommunicationCommentFromJSON,
    CommunicationCommentFromJSONTyped,
    CommunicationCommentToJSON,
} from './CommunicationComment';
import {
    CommunicationEmail,
    CommunicationEmailFromJSON,
    CommunicationEmailFromJSONTyped,
    CommunicationEmailToJSON,
} from './CommunicationEmail';
import {
    CommunicationLabelValue,
    CommunicationLabelValueFromJSON,
    CommunicationLabelValueFromJSONTyped,
    CommunicationLabelValueToJSON,
} from './CommunicationLabelValue';
import {
    CommunicationLike,
    CommunicationLikeFromJSON,
    CommunicationLikeFromJSONTyped,
    CommunicationLikeToJSON,
} from './CommunicationLike';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';
import {
    MedicalInstitution,
    MedicalInstitutionFromJSON,
    MedicalInstitutionFromJSONTyped,
    MedicalInstitutionToJSON,
} from './MedicalInstitution';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';
import {
    Task,
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';
import {
    Voc,
    VocFromJSON,
    VocFromJSONTyped,
    VocToJSON,
} from './Voc';

/**
 * コミュニケーション (全てのコミュニケーションタイプのプロパティが入っている)
 * @export
 * @interface Communication
 */
export interface Communication {
    /**
     * コミュニケーションID
     * @type {number}
     * @memberof Communication
     */
    id: number;
    /**
     * 
     * @type {CommunicationType}
     * @memberof Communication
     */
    communicationType: CommunicationType;
    /**
     * コミュニケーション日付
     * @type {string}
     * @memberof Communication
     */
    actedDate: string;
    /**
     * カテゴリー (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    category?: CommunicationCategoryEnum;
    /**
     * 目的 (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    purpose?: string | null;
    /**
     * ストーリー (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    story?: string | null;
    /**
     * 
     * @type {CommunicationEmail}
     * @memberof Communication
     */
    email?: CommunicationEmail | null;
    /**
     * 送付物 (communication_type が 'postal_item' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    attachments?: string | null;
    /**
     * イベント名 (communication_type が 'event_attendance' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    eventName?: string | null;
    /**
     * 面談内容メモ
     * @type {string}
     * @memberof Communication
     */
    memo: string | null;
    /**
     * その他メモ (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    otherMemo?: string | null;
    /**
     * 
     * @type {NullableStaff}
     * @memberof Communication
     */
    user: NullableStaff | null;
    /**
     * 当院訪問者 (communication_type が 'visit' 'new_visit' 'phone' 'email' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    personInChargeHospital?: string | null;
    /**
     * 先方対応者 (communication_type が 'visit' 'new_visit' 'phone' 'email' 'postal_item' 'event_attendance' のみ有効)
     * @type {string}
     * @memberof Communication
     */
    attendee?: string | null;
    /**
     * 先方医師との面談実施 (communication_type が 'visit' のみ有効)
     * @type {boolean}
     * @memberof Communication
     */
    isDoctorAttended?: boolean;
    /**
     * 
     * @type {MedicalInstitution}
     * @memberof Communication
     */
    medicalInstitution: MedicalInstitution;
    /**
     * タスク一覧
     * @type {Array<Task>}
     * @memberof Communication
     */
    tasks: Array<Task>;
    /**
     * コメント一覧
     * @type {Array<CommunicationComment>}
     * @memberof Communication
     */
    comments: Array<CommunicationComment>;
    /**
     * いいね一覧
     * @type {Array<CommunicationLike>}
     * @memberof Communication
     */
    likes: Array<CommunicationLike>;
    /**
     * コミュニケーションラベル一覧
     * @type {Array<CommunicationLabelValue>}
     * @memberof Communication
     */
    labelValues?: Array<CommunicationLabelValue>;
    /**
     * 連携先フィードバック一覧
     * @type {Array<Voc>}
     * @memberof Communication
     */
    vocs: Array<Voc>;
}

/**
* @export
* @enum {string}
*/
export enum CommunicationCategoryEnum {
    Sales = 'sales',
    Other = 'other'
}

export function CommunicationFromJSON(json: any): Communication {
    return CommunicationFromJSONTyped(json, false);
}

export function CommunicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Communication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'actedDate': json['acted_date'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'story': !exists(json, 'story') ? undefined : json['story'],
        'email': !exists(json, 'email') ? undefined : CommunicationEmailFromJSON(json['email']),
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'eventName': !exists(json, 'event_name') ? undefined : json['event_name'],
        'memo': json['memo'],
        'otherMemo': !exists(json, 'other_memo') ? undefined : json['other_memo'],
        'user': NullableStaffFromJSON(json['user']),
        'personInChargeHospital': !exists(json, 'person_in_charge_hospital') ? undefined : json['person_in_charge_hospital'],
        'attendee': !exists(json, 'attendee') ? undefined : json['attendee'],
        'isDoctorAttended': !exists(json, 'is_doctor_attended') ? undefined : json['is_doctor_attended'],
        'medicalInstitution': MedicalInstitutionFromJSON(json['medical_institution']),
        'tasks': ((json['tasks'] as Array<any>).map(TaskFromJSON)),
        'comments': ((json['comments'] as Array<any>).map(CommunicationCommentFromJSON)),
        'likes': ((json['likes'] as Array<any>).map(CommunicationLikeFromJSON)),
        'labelValues': !exists(json, 'label_values') ? undefined : ((json['label_values'] as Array<any>).map(CommunicationLabelValueFromJSON)),
        'vocs': ((json['vocs'] as Array<any>).map(VocFromJSON)),
    };
}

export function CommunicationToJSON(value?: Communication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'acted_date': value.actedDate,
        'category': value.category,
        'purpose': value.purpose,
        'story': value.story,
        'email': CommunicationEmailToJSON(value.email),
        'attachments': value.attachments,
        'event_name': value.eventName,
        'memo': value.memo,
        'other_memo': value.otherMemo,
        'user': NullableStaffToJSON(value.user),
        'person_in_charge_hospital': value.personInChargeHospital,
        'attendee': value.attendee,
        'is_doctor_attended': value.isDoctorAttended,
        'medical_institution': MedicalInstitutionToJSON(value.medicalInstitution),
        'tasks': ((value.tasks as Array<any>).map(TaskToJSON)),
        'comments': ((value.comments as Array<any>).map(CommunicationCommentToJSON)),
        'likes': ((value.likes as Array<any>).map(CommunicationLikeToJSON)),
        'label_values': value.labelValues === undefined ? undefined : ((value.labelValues as Array<any>).map(CommunicationLabelValueToJSON)),
        'vocs': ((value.vocs as Array<any>).map(VocToJSON)),
    };
}

