import { CSSProperties, HTMLAttributes } from 'react';

type Props = {
  children: React.ReactNode;
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  radius?: CSSProperties['borderRadius'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['minHeight'];
  overflow?: CSSProperties['overflow'];
  border?: CSSProperties['border'];
  onClick?: HTMLAttributes<HTMLDivElement>['onClick'];
};

export const Section: React.FC<Props> = ({
  width = '100%',
  height,
  minHeight,
  margin,
  padding,
  overflow,
  border,
  ...props
}) => {
  return (
    <div
      style={{
        display: 'block',
        margin,
        padding,
        width,
        height,
        minHeight,
        overflow,
        border,
        cursor: props.onClick ? 'pointer' : undefined,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
