import { Toast } from '@/src/components/foundations/Feedback/Toast';
import { Scrim } from '@/src/components/foundations/Utils/Scrim';
import { useTitle } from '@/src/hooks/useTitle';
import { Layout } from '@/src/layouts';
import { Auth } from '@/src/middlewares/Auth';
import ErrorBoundary from '@/src/middlewares/ErrorBoundary';
import { GoogleAnalytics } from '@/src/middlewares/GoogleAnalytics';
import { SafeHydrate } from '@/src/middlewares/SafeHydrate';
import { AppPageProps } from '@/src/types/app';
import { setupErrorCapture } from '@/src/utils/errorCapture';

import Head from 'next/head';

import '@/styles/rsuite/index.less';
import 'destyle.css';
import '@/styles/globals.scss';

const MyApp = ({ Component, pageProps }: AppPageProps): JSX.Element => {
  setupErrorCapture();

  const title = useTitle();

  return (
    <SafeHydrate>
      <Head>
        <title>{title} - foro CRM</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover"
          charSet="utf-8"
        />
        <link rel="icon" href="/app/favicon.ico" type="image/x-icon" />
      </Head>
      <ErrorBoundary>
        <GoogleAnalytics />
        <Auth>
          <Layout
            type={pageProps.layout}
            options={{
              pc: pageProps.layoutOptionForPc,
              sp: pageProps.layoutOptionForSp,
            }}
          >
            <Component {...pageProps} />
          </Layout>
          <div id="tooltip-root" />
          <div id="modeless-window-root" />
          <div id="modal-root" />
          <Toast />
          <Scrim />
        </Auth>
      </ErrorBoundary>
    </SafeHydrate>
  );
};

export default MyApp;
