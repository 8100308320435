/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    PutVocGroupsRequest,
    PutVocGroupsRequestFromJSON,
    PutVocGroupsRequestToJSON,
    PutVocGroupsResponse,
    PutVocGroupsResponseFromJSON,
    PutVocGroupsResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PutVocGroupsOperationRequest {
    putVocGroupsRequest: PutVocGroupsRequest;
}

/**
 * VocGroupsApi - interface
 * 
 * @export
 * @interface VocGroupsApiInterface
 */
export interface VocGroupsApiInterface {
    /**
     * 
     * @summary VOCグループ設定を一括更新する
     * @param {PutVocGroupsRequest} putVocGroupsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VocGroupsApiInterface
     */
    putVocGroupsRaw(requestParameters: PutVocGroupsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutVocGroupsResponse>>;

    /**
     * VOCグループ設定を一括更新する
     */
    putVocGroups(requestParameters: PutVocGroupsOperationRequest, initOverrides?: RequestInit): Promise<PutVocGroupsResponse>;

}

/**
 * 
 */
export class VocGroupsApi extends runtime.BaseAPI implements VocGroupsApiInterface {

    /**
     * VOCグループ設定を一括更新する
     */
    async putVocGroupsRaw(requestParameters: PutVocGroupsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutVocGroupsResponse>> {
        if (requestParameters.putVocGroupsRequest === null || requestParameters.putVocGroupsRequest === undefined) {
            throw new runtime.RequiredError('putVocGroupsRequest','Required parameter requestParameters.putVocGroupsRequest was null or undefined when calling putVocGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/voc_groups`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutVocGroupsRequestToJSON(requestParameters.putVocGroupsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutVocGroupsResponseFromJSON(jsonValue));
    }

    /**
     * VOCグループ設定を一括更新する
     */
    async putVocGroups(requestParameters: PutVocGroupsOperationRequest, initOverrides?: RequestInit): Promise<PutVocGroupsResponse> {
        const response = await this.putVocGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
