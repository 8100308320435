/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutMedicalInstitutionSpecialNoteRequest
 */
export interface PutMedicalInstitutionSpecialNoteRequest {
    /**
     * 特記事項の内容
     * @type {string}
     * @memberof PutMedicalInstitutionSpecialNoteRequest
     */
    content: string;
}

export function PutMedicalInstitutionSpecialNoteRequestFromJSON(json: any): PutMedicalInstitutionSpecialNoteRequest {
    return PutMedicalInstitutionSpecialNoteRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionSpecialNoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionSpecialNoteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
    };
}

export function PutMedicalInstitutionSpecialNoteRequestToJSON(value?: PutMedicalInstitutionSpecialNoteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
    };
}

