/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VocPriorityEnum,
    VocPriorityEnumFromJSON,
    VocPriorityEnumFromJSONTyped,
    VocPriorityEnumToJSON,
} from './VocPriorityEnum';
import {
    VocStatusEnum,
    VocStatusEnumFromJSON,
    VocStatusEnumFromJSONTyped,
    VocStatusEnumToJSON,
} from './VocStatusEnum';

/**
 * 
 * @export
 * @interface PostVocRequest
 */
export interface PostVocRequest {
    /**
     * 紐づける医療機関ID
     * @type {number}
     * @memberof PostVocRequest
     */
    medicalInstitutionId: number;
    /**
     * タイトル
     * @type {string}
     * @memberof PostVocRequest
     */
    title: string;
    /**
     * 聞き取り日 (YYYY-MM-DD)
     * @type {Date}
     * @memberof PostVocRequest
     */
    reportedDate: Date;
    /**
     * 
     * @type {VocStatusEnum}
     * @memberof PostVocRequest
     */
    status: VocStatusEnum;
    /**
     * 
     * @type {VocPriorityEnum}
     * @memberof PostVocRequest
     */
    priority?: VocPriorityEnum;
    /**
     * フィードバック詳細
     * @type {string}
     * @memberof PostVocRequest
     */
    detailText?: string | null;
    /**
     * 対応内容
     * @type {string}
     * @memberof PostVocRequest
     */
    actionText?: string | null;
    /**
     * 担当者ID
     * @type {number}
     * @memberof PostVocRequest
     */
    userId?: number | null;
    /**
     * 連携先フィードバックグループID
     * @type {string}
     * @memberof PostVocRequest
     */
    vocGroupId?: string | null;
    /**
     * 連携先フィードバックに紐づくコミュニケーションIDの配列
     * @type {Array<number>}
     * @memberof PostVocRequest
     */
    communicationIds?: Array<number> | null;
}

export function PostVocRequestFromJSON(json: any): PostVocRequest {
    return PostVocRequestFromJSONTyped(json, false);
}

export function PostVocRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostVocRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionId': json['medical_institution_id'],
        'title': json['title'],
        'reportedDate': (new Date(json['reported_date'])),
        'status': VocStatusEnumFromJSON(json['status']),
        'priority': !exists(json, 'priority') ? undefined : VocPriorityEnumFromJSON(json['priority']),
        'detailText': !exists(json, 'detail_text') ? undefined : json['detail_text'],
        'actionText': !exists(json, 'action_text') ? undefined : json['action_text'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'vocGroupId': !exists(json, 'voc_group_id') ? undefined : json['voc_group_id'],
        'communicationIds': !exists(json, 'communication_ids') ? undefined : json['communication_ids'],
    };
}

export function PostVocRequestToJSON(value?: PostVocRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_id': value.medicalInstitutionId,
        'title': value.title,
        'reported_date': (value.reportedDate.toISOString().substr(0,10)),
        'status': VocStatusEnumToJSON(value.status),
        'priority': VocPriorityEnumToJSON(value.priority),
        'detail_text': value.detailText,
        'action_text': value.actionText,
        'user_id': value.userId,
        'voc_group_id': value.vocGroupId,
        'communication_ids': value.communicationIds,
    };
}

