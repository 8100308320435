import { create } from 'zustand';

type ScrimState = { isShown: false } | { isShown: true; message?: string };

const scrimStore = create<{
  scrim: ScrimState;
  showScrim: (message?: string) => void;
  hideScrim: VoidFunction;
}>((set) => ({
  scrim: { isShown: false },
  showScrim: (message?: string) => set({ scrim: { isShown: true, message } }),
  hideScrim: () => set({ scrim: { isShown: false } }),
}));

export const useScrim = (): {
  scrim: ScrimState;
  show: (message?: string) => void;
  hide: VoidFunction;
} => {
  const { scrim, showScrim, hideScrim } = scrimStore();

  return {
    scrim,
    show: showScrim,
    hide: hideScrim,
  };
};
