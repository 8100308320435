/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 検索時に渡す ids を or / and / nand / nor 検索にするかを表す。ids を指定しない場合は同様に condition も指定しない。
 * @export
 * @enum {string}
 */
export enum IdsSearchCondition {
    And = 'and',
    Or = 'or',
    Nand = 'nand',
    Nor = 'nor'
}

export function IdsSearchConditionFromJSON(json: any): IdsSearchCondition {
    return IdsSearchConditionFromJSONTyped(json, false);
}

export function IdsSearchConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdsSearchCondition {
    return json as IdsSearchCondition;
}

export function IdsSearchConditionToJSON(value?: IdsSearchCondition | null): any {
    return value as any;
}

