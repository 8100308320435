/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Communication,
    CommunicationFromJSON,
    CommunicationToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetDepartmentsResponse,
    GetDepartmentsResponseFromJSON,
    GetDepartmentsResponseToJSON,
    GetFacilityTypeGroupsResponse,
    GetFacilityTypeGroupsResponseFromJSON,
    GetFacilityTypeGroupsResponseToJSON,
    GetMedicalInstitutionActionListsResponse,
    GetMedicalInstitutionActionListsResponseFromJSON,
    GetMedicalInstitutionActionListsResponseToJSON,
    GetMedicalInstitutionLabelValuesResponse,
    GetMedicalInstitutionLabelValuesResponseFromJSON,
    GetMedicalInstitutionLabelValuesResponseToJSON,
    GetMedicalInstitutionReferralsResponse,
    GetMedicalInstitutionReferralsResponseFromJSON,
    GetMedicalInstitutionReferralsResponseToJSON,
    GetMedicalInstitutionSegmentsResponse,
    GetMedicalInstitutionSegmentsResponseFromJSON,
    GetMedicalInstitutionSegmentsResponseToJSON,
    GetMedicalInstitutionTagsResponse,
    GetMedicalInstitutionTagsResponseFromJSON,
    GetMedicalInstitutionTagsResponseToJSON,
    MedicalInstitutionMemo,
    MedicalInstitutionMemoFromJSON,
    MedicalInstitutionMemoToJSON,
    MedicalInstitutionSpecialNote,
    MedicalInstitutionSpecialNoteFromJSON,
    MedicalInstitutionSpecialNoteToJSON,
    MedicalInstitutionSummary,
    MedicalInstitutionSummaryFromJSON,
    MedicalInstitutionSummaryToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    NullableMedicalInstitutionPhase,
    NullableMedicalInstitutionPhaseFromJSON,
    NullableMedicalInstitutionPhaseToJSON,
    PostCommunicationsSearchResultsRequest,
    PostCommunicationsSearchResultsRequestFromJSON,
    PostCommunicationsSearchResultsRequestToJSON,
    PostCommunicationsSearchResultsResponse,
    PostCommunicationsSearchResultsResponseFromJSON,
    PostCommunicationsSearchResultsResponseToJSON,
    PostMedicalInstitutionCommunicationRequest,
    PostMedicalInstitutionCommunicationRequestFromJSON,
    PostMedicalInstitutionCommunicationRequestToJSON,
    PostMedicalInstitutionLabelValueRequest,
    PostMedicalInstitutionLabelValueRequestFromJSON,
    PostMedicalInstitutionLabelValueRequestToJSON,
    PostMedicalInstitutionLabelValuesResponse,
    PostMedicalInstitutionLabelValuesResponseFromJSON,
    PostMedicalInstitutionLabelValuesResponseToJSON,
    PostMedicalInstitutionReferralsRequest,
    PostMedicalInstitutionReferralsRequestFromJSON,
    PostMedicalInstitutionReferralsRequestToJSON,
    PostMedicalInstitutionReferralsResponse,
    PostMedicalInstitutionReferralsResponseFromJSON,
    PostMedicalInstitutionReferralsResponseToJSON,
    PostMedicalInstitutionReverseReferralsRequest,
    PostMedicalInstitutionReverseReferralsRequestFromJSON,
    PostMedicalInstitutionReverseReferralsRequestToJSON,
    PostMedicalInstitutionReverseReferralsResponse,
    PostMedicalInstitutionReverseReferralsResponseFromJSON,
    PostMedicalInstitutionReverseReferralsResponseToJSON,
    PostMedicalInstitutionSearchRequest,
    PostMedicalInstitutionSearchRequestFromJSON,
    PostMedicalInstitutionSearchRequestToJSON,
    PostMedicalInstitutionSearchResponse,
    PostMedicalInstitutionSearchResponseFromJSON,
    PostMedicalInstitutionSearchResponseToJSON,
    PutMedicalInstitutionMemoRequest,
    PutMedicalInstitutionMemoRequestFromJSON,
    PutMedicalInstitutionMemoRequestToJSON,
    PutMedicalInstitutionPhaseRequest,
    PutMedicalInstitutionPhaseRequestFromJSON,
    PutMedicalInstitutionPhaseRequestToJSON,
    PutMedicalInstitutionSpecialNoteRequest,
    PutMedicalInstitutionSpecialNoteRequestFromJSON,
    PutMedicalInstitutionSpecialNoteRequestToJSON,
    PutMedicalInstitutionTagsRequest,
    PutMedicalInstitutionTagsRequestFromJSON,
    PutMedicalInstitutionTagsRequestToJSON,
    PutMedicalInstitutionTagsResponse,
    PutMedicalInstitutionTagsResponseFromJSON,
    PutMedicalInstitutionTagsResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface GetMedicalInstitutionActionListsRequest {
    medicalInstitutionId: number;
}

export interface GetMedicalInstitutionLabelValuesRequest {
    medicalInstitutionId: number;
}

export interface GetMedicalInstitutionMemoRequest {
    id: number;
}

export interface GetMedicalInstitutionReferralsRequest {
    id: number;
    page?: number;
    perPage?: number;
}

export interface GetMedicalInstitutionSegmentsRequest {
    medicalInstitutionId: number;
}

export interface GetMedicalInstitutionSummaryRequest {
    id: number;
}

export interface GetMedicalInstitutionTagsRequest {
    id: number;
}

export interface PostMedicalInstitutionCommunicationOperationRequest {
    medicalInstitutionId: number;
    postMedicalInstitutionCommunicationRequest: PostMedicalInstitutionCommunicationRequest;
}

export interface PostMedicalInstitutionLabelValuesRequest {
    medicalInstitutionId: number;
    postMedicalInstitutionLabelValueRequest: PostMedicalInstitutionLabelValueRequest;
}

export interface PostMedicalInstitutionReferralsOperationRequest {
    id: number;
    postMedicalInstitutionReferralsRequest: PostMedicalInstitutionReferralsRequest;
}

export interface PostMedicalInstitutionReverseReferralsOperationRequest {
    id: number;
    postMedicalInstitutionReverseReferralsRequest: PostMedicalInstitutionReverseReferralsRequest;
}

export interface PostMedicalInstitutionSearchOperationRequest {
    postMedicalInstitutionSearchRequest: PostMedicalInstitutionSearchRequest;
}

export interface PostMedicalInstitutionsCommunicationsSearchResultsRequest {
    id: number;
    postCommunicationsSearchResultsRequest: PostCommunicationsSearchResultsRequest;
}

export interface PutMedicalInstitutionMemoOperationRequest {
    id: number;
    putMedicalInstitutionMemoRequest: PutMedicalInstitutionMemoRequest;
}

export interface PutMedicalInstitutionPhaseOperationRequest {
    medicalInstitutionId: number;
    putMedicalInstitutionPhaseRequest: PutMedicalInstitutionPhaseRequest;
}

export interface PutMedicalInstitutionSpecialNoteOperationRequest {
    id: number;
    putMedicalInstitutionSpecialNoteRequest: PutMedicalInstitutionSpecialNoteRequest;
}

export interface PutMedicalInstitutionTagsOperationRequest {
    id: number;
    putMedicalInstitutionTagsRequest: PutMedicalInstitutionTagsRequest;
}

/**
 * MedicalInstitutionsApi - interface
 * 
 * @export
 * @interface MedicalInstitutionsApiInterface
 */
export interface MedicalInstitutionsApiInterface {
    /**
     * 診療科目の一覧を返す 医療機関検索の絞り込みクエリーとして使うこともできる 
     * @summary 診療科目の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDepartmentsResponse>>;

    /**
     * 診療科目の一覧を返す 医療機関検索の絞り込みクエリーとして使うこともできる 
     * 診療科目の取得
     */
    getDepartments(initOverrides?: RequestInit): Promise<GetDepartmentsResponse>;

    /**
     * グルーピングした施設タイプの取得 
     * @summary グルーピングした施設タイプの取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getFacilityTypeGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetFacilityTypeGroupsResponse>>;

    /**
     * グルーピングした施設タイプの取得 
     * グルーピングした施設タイプの取得
     */
    getFacilityTypeGroups(initOverrides?: RequestInit): Promise<GetFacilityTypeGroupsResponse>;

    /**
     * 医療機関に紐付いているアクションリスト一覧を取得する 
     * @summary アクションリスト一覧の取得
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionActionListsRaw(requestParameters: GetMedicalInstitutionActionListsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionActionListsResponse>>;

    /**
     * 医療機関に紐付いているアクションリスト一覧を取得する 
     * アクションリスト一覧の取得
     */
    getMedicalInstitutionActionLists(requestParameters: GetMedicalInstitutionActionListsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionActionListsResponse>;

    /**
     * 個別医療機関の医療機関ラベル設定値を一覧で取得する
     * @summary 医療機関ラベルの設定値一覧取得
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionLabelValuesRaw(requestParameters: GetMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionLabelValuesResponse>>;

    /**
     * 個別医療機関の医療機関ラベル設定値を一覧で取得する
     * 医療機関ラベルの設定値一覧取得
     */
    getMedicalInstitutionLabelValues(requestParameters: GetMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionLabelValuesResponse>;

    /**
     * 指定した医療機関に紐づくメモが取得できる  メモをまだ未作成の場合でも404にはならず、空のメモ内容が返ります 
     * @summary 医療機関メモの取得
     * @param {number} id 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionMemoRaw(requestParameters: GetMedicalInstitutionMemoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionMemo>>;

    /**
     * 指定した医療機関に紐づくメモが取得できる  メモをまだ未作成の場合でも404にはならず、空のメモ内容が返ります 
     * 医療機関メモの取得
     */
    getMedicalInstitutionMemo(requestParameters: GetMedicalInstitutionMemoRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionMemo>;

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * @summary 医療機関ごとの紹介一覧の取得
     * @param {number} id 医療機関ID
     * @param {number} [page] ページ番号（１始まり）
     * @param {number} [perPage] １ページあたりの件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionReferralsRaw(requestParameters: GetMedicalInstitutionReferralsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionReferralsResponse>>;

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * 医療機関ごとの紹介一覧の取得
     */
    getMedicalInstitutionReferrals(requestParameters: GetMedicalInstitutionReferralsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionReferralsResponse>;

    /**
     * 医療機関の連携先セグメント履歴を取得する 
     * @summary 連携先セグメント履歴の取得
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionSegmentsRaw(requestParameters: GetMedicalInstitutionSegmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionSegmentsResponse>>;

    /**
     * 医療機関の連携先セグメント履歴を取得する 
     * 連携先セグメント履歴の取得
     */
    getMedicalInstitutionSegments(requestParameters: GetMedicalInstitutionSegmentsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionSegmentsResponse>;

    /**
     * 医療機関のサマリー情報が取得できる 
     * @summary 医療機関のサマリー情報の取得
     * @param {number} id 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionSummaryRaw(requestParameters: GetMedicalInstitutionSummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionSummary>>;

    /**
     * 医療機関のサマリー情報が取得できる 
     * 医療機関のサマリー情報の取得
     */
    getMedicalInstitutionSummary(requestParameters: GetMedicalInstitutionSummaryRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionSummary>;

    /**
     * 医療機関に紐付いているタグ一覧を取得する 
     * @summary タグ一覧の取得
     * @param {number} id 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    getMedicalInstitutionTagsRaw(requestParameters: GetMedicalInstitutionTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionTagsResponse>>;

    /**
     * 医療機関に紐付いているタグ一覧を取得する 
     * タグ一覧の取得
     */
    getMedicalInstitutionTags(requestParameters: GetMedicalInstitutionTagsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionTagsResponse>;

    /**
     * コミュニケーションとタスクを登録する。communication_type で指定したタイプに合う属性を設定すること
     * @summary コミュニケーションの登録
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {PostMedicalInstitutionCommunicationRequest} postMedicalInstitutionCommunicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    postMedicalInstitutionCommunicationRaw(requestParameters: PostMedicalInstitutionCommunicationOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Communication>>;

    /**
     * コミュニケーションとタスクを登録する。communication_type で指定したタイプに合う属性を設定すること
     * コミュニケーションの登録
     */
    postMedicalInstitutionCommunication(requestParameters: PostMedicalInstitutionCommunicationOperationRequest, initOverrides?: RequestInit): Promise<Communication>;

    /**
     * 個別医療機関の医療機関ラベル設定値を一括で設定する
     * @summary 医療機関ラベル設定値の一括編集
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {PostMedicalInstitutionLabelValueRequest} postMedicalInstitutionLabelValueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    postMedicalInstitutionLabelValuesRaw(requestParameters: PostMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionLabelValuesResponse>>;

    /**
     * 個別医療機関の医療機関ラベル設定値を一括で設定する
     * 医療機関ラベル設定値の一括編集
     */
    postMedicalInstitutionLabelValues(requestParameters: PostMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionLabelValuesResponse>;

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * @summary 医療機関ごとの紹介一覧の取得
     * @param {number} id 医療機関ID
     * @param {PostMedicalInstitutionReferralsRequest} postMedicalInstitutionReferralsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    postMedicalInstitutionReferralsRaw(requestParameters: PostMedicalInstitutionReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionReferralsResponse>>;

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * 医療機関ごとの紹介一覧の取得
     */
    postMedicalInstitutionReferrals(requestParameters: PostMedicalInstitutionReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionReferralsResponse>;

    /**
     * 個別の医療機関から来た逆紹介情報の一覧を取得できる 
     * @summary 医療機関ごとの逆紹介一覧の取得
     * @param {number} id 医療機関ID
     * @param {PostMedicalInstitutionReverseReferralsRequest} postMedicalInstitutionReverseReferralsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    postMedicalInstitutionReverseReferralsRaw(requestParameters: PostMedicalInstitutionReverseReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionReverseReferralsResponse>>;

    /**
     * 個別の医療機関から来た逆紹介情報の一覧を取得できる 
     * 医療機関ごとの逆紹介一覧の取得
     */
    postMedicalInstitutionReverseReferrals(requestParameters: PostMedicalInstitutionReverseReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionReverseReferralsResponse>;

    /**
     * 医療機関を検索できる   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * @summary 医療機関の検索結果を取得
     * @param {PostMedicalInstitutionSearchRequest} postMedicalInstitutionSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    postMedicalInstitutionSearchRaw(requestParameters: PostMedicalInstitutionSearchOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionSearchResponse>>;

    /**
     * 医療機関を検索できる   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * 医療機関の検索結果を取得
     */
    postMedicalInstitutionSearch(requestParameters: PostMedicalInstitutionSearchOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionSearchResponse>;

    /**
     * 医療機関のコミュニケーションを検索する 
     * @summary 医療機関に紐づくコミュニケーションの検索結果取得
     * @param {number} id 医療機関ID
     * @param {PostCommunicationsSearchResultsRequest} postCommunicationsSearchResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    postMedicalInstitutionsCommunicationsSearchResultsRaw(requestParameters: PostMedicalInstitutionsCommunicationsSearchResultsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostCommunicationsSearchResultsResponse>>;

    /**
     * 医療機関のコミュニケーションを検索する 
     * 医療機関に紐づくコミュニケーションの検索結果取得
     */
    postMedicalInstitutionsCommunicationsSearchResults(requestParameters: PostMedicalInstitutionsCommunicationsSearchResultsRequest, initOverrides?: RequestInit): Promise<PostCommunicationsSearchResultsResponse>;

    /**
     * 医療機関メモを更新する 
     * @summary 医療機関メモの更新
     * @param {number} id 医療機関ID
     * @param {PutMedicalInstitutionMemoRequest} putMedicalInstitutionMemoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    putMedicalInstitutionMemoRaw(requestParameters: PutMedicalInstitutionMemoOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionMemo>>;

    /**
     * 医療機関メモを更新する 
     * 医療機関メモの更新
     */
    putMedicalInstitutionMemo(requestParameters: PutMedicalInstitutionMemoOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionMemo>;

    /**
     * 
     * @summary フェーズ更新
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {PutMedicalInstitutionPhaseRequest} putMedicalInstitutionPhaseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    putMedicalInstitutionPhaseRaw(requestParameters: PutMedicalInstitutionPhaseOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NullableMedicalInstitutionPhase>>;

    /**
     * フェーズ更新
     */
    putMedicalInstitutionPhase(requestParameters: PutMedicalInstitutionPhaseOperationRequest, initOverrides?: RequestInit): Promise<NullableMedicalInstitutionPhase>;

    /**
     * 医療機関特記事項を更新する 
     * @summary 医療機関特記事項の更新
     * @param {number} id 医療機関ID
     * @param {PutMedicalInstitutionSpecialNoteRequest} putMedicalInstitutionSpecialNoteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    putMedicalInstitutionSpecialNoteRaw(requestParameters: PutMedicalInstitutionSpecialNoteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionSpecialNote>>;

    /**
     * 医療機関特記事項を更新する 
     * 医療機関特記事項の更新
     */
    putMedicalInstitutionSpecialNote(requestParameters: PutMedicalInstitutionSpecialNoteOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionSpecialNote>;

    /**
     * 
     * @summary タグ一覧の更新
     * @param {number} id 医療機関ID
     * @param {PutMedicalInstitutionTagsRequest} putMedicalInstitutionTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionsApiInterface
     */
    putMedicalInstitutionTagsRaw(requestParameters: PutMedicalInstitutionTagsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutMedicalInstitutionTagsResponse>>;

    /**
     * タグ一覧の更新
     */
    putMedicalInstitutionTags(requestParameters: PutMedicalInstitutionTagsOperationRequest, initOverrides?: RequestInit): Promise<PutMedicalInstitutionTagsResponse>;

}

/**
 * 
 */
export class MedicalInstitutionsApi extends runtime.BaseAPI implements MedicalInstitutionsApiInterface {

    /**
     * 診療科目の一覧を返す 医療機関検索の絞り込みクエリーとして使うこともできる 
     * 診療科目の取得
     */
    async getDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDepartmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/departments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDepartmentsResponseFromJSON(jsonValue));
    }

    /**
     * 診療科目の一覧を返す 医療機関検索の絞り込みクエリーとして使うこともできる 
     * 診療科目の取得
     */
    async getDepartments(initOverrides?: RequestInit): Promise<GetDepartmentsResponse> {
        const response = await this.getDepartmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * グルーピングした施設タイプの取得 
     * グルーピングした施設タイプの取得
     */
    async getFacilityTypeGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetFacilityTypeGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/facility_type_groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFacilityTypeGroupsResponseFromJSON(jsonValue));
    }

    /**
     * グルーピングした施設タイプの取得 
     * グルーピングした施設タイプの取得
     */
    async getFacilityTypeGroups(initOverrides?: RequestInit): Promise<GetFacilityTypeGroupsResponse> {
        const response = await this.getFacilityTypeGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 医療機関に紐付いているアクションリスト一覧を取得する 
     * アクションリスト一覧の取得
     */
    async getMedicalInstitutionActionListsRaw(requestParameters: GetMedicalInstitutionActionListsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionActionListsResponse>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling getMedicalInstitutionActionLists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/action_lists`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionActionListsResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関に紐付いているアクションリスト一覧を取得する 
     * アクションリスト一覧の取得
     */
    async getMedicalInstitutionActionLists(requestParameters: GetMedicalInstitutionActionListsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionActionListsResponse> {
        const response = await this.getMedicalInstitutionActionListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別医療機関の医療機関ラベル設定値を一覧で取得する
     * 医療機関ラベルの設定値一覧取得
     */
    async getMedicalInstitutionLabelValuesRaw(requestParameters: GetMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionLabelValuesResponse>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling getMedicalInstitutionLabelValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/label_values`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionLabelValuesResponseFromJSON(jsonValue));
    }

    /**
     * 個別医療機関の医療機関ラベル設定値を一覧で取得する
     * 医療機関ラベルの設定値一覧取得
     */
    async getMedicalInstitutionLabelValues(requestParameters: GetMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionLabelValuesResponse> {
        const response = await this.getMedicalInstitutionLabelValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 指定した医療機関に紐づくメモが取得できる  メモをまだ未作成の場合でも404にはならず、空のメモ内容が返ります 
     * 医療機関メモの取得
     */
    async getMedicalInstitutionMemoRaw(requestParameters: GetMedicalInstitutionMemoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionMemo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMedicalInstitutionMemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/memo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionMemoFromJSON(jsonValue));
    }

    /**
     * 指定した医療機関に紐づくメモが取得できる  メモをまだ未作成の場合でも404にはならず、空のメモ内容が返ります 
     * 医療機関メモの取得
     */
    async getMedicalInstitutionMemo(requestParameters: GetMedicalInstitutionMemoRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionMemo> {
        const response = await this.getMedicalInstitutionMemoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * 医療機関ごとの紹介一覧の取得
     */
    async getMedicalInstitutionReferralsRaw(requestParameters: GetMedicalInstitutionReferralsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionReferralsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMedicalInstitutionReferrals.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/referrals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionReferralsResponseFromJSON(jsonValue));
    }

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * 医療機関ごとの紹介一覧の取得
     */
    async getMedicalInstitutionReferrals(requestParameters: GetMedicalInstitutionReferralsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionReferralsResponse> {
        const response = await this.getMedicalInstitutionReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関の連携先セグメント履歴を取得する 
     * 連携先セグメント履歴の取得
     */
    async getMedicalInstitutionSegmentsRaw(requestParameters: GetMedicalInstitutionSegmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionSegmentsResponse>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling getMedicalInstitutionSegments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/segments`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionSegmentsResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関の連携先セグメント履歴を取得する 
     * 連携先セグメント履歴の取得
     */
    async getMedicalInstitutionSegments(requestParameters: GetMedicalInstitutionSegmentsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionSegmentsResponse> {
        const response = await this.getMedicalInstitutionSegmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関のサマリー情報が取得できる 
     * 医療機関のサマリー情報の取得
     */
    async getMedicalInstitutionSummaryRaw(requestParameters: GetMedicalInstitutionSummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionSummary>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMedicalInstitutionSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionSummaryFromJSON(jsonValue));
    }

    /**
     * 医療機関のサマリー情報が取得できる 
     * 医療機関のサマリー情報の取得
     */
    async getMedicalInstitutionSummary(requestParameters: GetMedicalInstitutionSummaryRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionSummary> {
        const response = await this.getMedicalInstitutionSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関に紐付いているタグ一覧を取得する 
     * タグ一覧の取得
     */
    async getMedicalInstitutionTagsRaw(requestParameters: GetMedicalInstitutionTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionTagsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMedicalInstitutionTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/tags`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionTagsResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関に紐付いているタグ一覧を取得する 
     * タグ一覧の取得
     */
    async getMedicalInstitutionTags(requestParameters: GetMedicalInstitutionTagsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionTagsResponse> {
        const response = await this.getMedicalInstitutionTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションとタスクを登録する。communication_type で指定したタイプに合う属性を設定すること
     * コミュニケーションの登録
     */
    async postMedicalInstitutionCommunicationRaw(requestParameters: PostMedicalInstitutionCommunicationOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Communication>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling postMedicalInstitutionCommunication.');
        }

        if (requestParameters.postMedicalInstitutionCommunicationRequest === null || requestParameters.postMedicalInstitutionCommunicationRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionCommunicationRequest','Required parameter requestParameters.postMedicalInstitutionCommunicationRequest was null or undefined when calling postMedicalInstitutionCommunication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/communications`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionCommunicationRequestToJSON(requestParameters.postMedicalInstitutionCommunicationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationFromJSON(jsonValue));
    }

    /**
     * コミュニケーションとタスクを登録する。communication_type で指定したタイプに合う属性を設定すること
     * コミュニケーションの登録
     */
    async postMedicalInstitutionCommunication(requestParameters: PostMedicalInstitutionCommunicationOperationRequest, initOverrides?: RequestInit): Promise<Communication> {
        const response = await this.postMedicalInstitutionCommunicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別医療機関の医療機関ラベル設定値を一括で設定する
     * 医療機関ラベル設定値の一括編集
     */
    async postMedicalInstitutionLabelValuesRaw(requestParameters: PostMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionLabelValuesResponse>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling postMedicalInstitutionLabelValues.');
        }

        if (requestParameters.postMedicalInstitutionLabelValueRequest === null || requestParameters.postMedicalInstitutionLabelValueRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionLabelValueRequest','Required parameter requestParameters.postMedicalInstitutionLabelValueRequest was null or undefined when calling postMedicalInstitutionLabelValues.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/label_values`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionLabelValueRequestToJSON(requestParameters.postMedicalInstitutionLabelValueRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionLabelValuesResponseFromJSON(jsonValue));
    }

    /**
     * 個別医療機関の医療機関ラベル設定値を一括で設定する
     * 医療機関ラベル設定値の一括編集
     */
    async postMedicalInstitutionLabelValues(requestParameters: PostMedicalInstitutionLabelValuesRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionLabelValuesResponse> {
        const response = await this.postMedicalInstitutionLabelValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * 医療機関ごとの紹介一覧の取得
     */
    async postMedicalInstitutionReferralsRaw(requestParameters: PostMedicalInstitutionReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionReferralsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postMedicalInstitutionReferrals.');
        }

        if (requestParameters.postMedicalInstitutionReferralsRequest === null || requestParameters.postMedicalInstitutionReferralsRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionReferralsRequest','Required parameter requestParameters.postMedicalInstitutionReferralsRequest was null or undefined when calling postMedicalInstitutionReferrals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/referrals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionReferralsRequestToJSON(requestParameters.postMedicalInstitutionReferralsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionReferralsResponseFromJSON(jsonValue));
    }

    /**
     * 個別の医療機関から来た紹介情報の一覧を取得できる 
     * 医療機関ごとの紹介一覧の取得
     */
    async postMedicalInstitutionReferrals(requestParameters: PostMedicalInstitutionReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionReferralsResponse> {
        const response = await this.postMedicalInstitutionReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別の医療機関から来た逆紹介情報の一覧を取得できる 
     * 医療機関ごとの逆紹介一覧の取得
     */
    async postMedicalInstitutionReverseReferralsRaw(requestParameters: PostMedicalInstitutionReverseReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionReverseReferralsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postMedicalInstitutionReverseReferrals.');
        }

        if (requestParameters.postMedicalInstitutionReverseReferralsRequest === null || requestParameters.postMedicalInstitutionReverseReferralsRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionReverseReferralsRequest','Required parameter requestParameters.postMedicalInstitutionReverseReferralsRequest was null or undefined when calling postMedicalInstitutionReverseReferrals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/reverse_referrals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionReverseReferralsRequestToJSON(requestParameters.postMedicalInstitutionReverseReferralsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionReverseReferralsResponseFromJSON(jsonValue));
    }

    /**
     * 個別の医療機関から来た逆紹介情報の一覧を取得できる 
     * 医療機関ごとの逆紹介一覧の取得
     */
    async postMedicalInstitutionReverseReferrals(requestParameters: PostMedicalInstitutionReverseReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionReverseReferralsResponse> {
        const response = await this.postMedicalInstitutionReverseReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関を検索できる   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * 医療機関の検索結果を取得
     */
    async postMedicalInstitutionSearchRaw(requestParameters: PostMedicalInstitutionSearchOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionSearchResponse>> {
        if (requestParameters.postMedicalInstitutionSearchRequest === null || requestParameters.postMedicalInstitutionSearchRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionSearchRequest','Required parameter requestParameters.postMedicalInstitutionSearchRequest was null or undefined when calling postMedicalInstitutionSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/search_results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionSearchRequestToJSON(requestParameters.postMedicalInstitutionSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionSearchResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関を検索できる   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * 医療機関の検索結果を取得
     */
    async postMedicalInstitutionSearch(requestParameters: PostMedicalInstitutionSearchOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionSearchResponse> {
        const response = await this.postMedicalInstitutionSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関のコミュニケーションを検索する 
     * 医療機関に紐づくコミュニケーションの検索結果取得
     */
    async postMedicalInstitutionsCommunicationsSearchResultsRaw(requestParameters: PostMedicalInstitutionsCommunicationsSearchResultsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostCommunicationsSearchResultsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postMedicalInstitutionsCommunicationsSearchResults.');
        }

        if (requestParameters.postCommunicationsSearchResultsRequest === null || requestParameters.postCommunicationsSearchResultsRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationsSearchResultsRequest','Required parameter requestParameters.postCommunicationsSearchResultsRequest was null or undefined when calling postMedicalInstitutionsCommunicationsSearchResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/communications/search_results`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationsSearchResultsRequestToJSON(requestParameters.postCommunicationsSearchResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCommunicationsSearchResultsResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関のコミュニケーションを検索する 
     * 医療機関に紐づくコミュニケーションの検索結果取得
     */
    async postMedicalInstitutionsCommunicationsSearchResults(requestParameters: PostMedicalInstitutionsCommunicationsSearchResultsRequest, initOverrides?: RequestInit): Promise<PostCommunicationsSearchResultsResponse> {
        const response = await this.postMedicalInstitutionsCommunicationsSearchResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関メモを更新する 
     * 医療機関メモの更新
     */
    async putMedicalInstitutionMemoRaw(requestParameters: PutMedicalInstitutionMemoOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionMemo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putMedicalInstitutionMemo.');
        }

        if (requestParameters.putMedicalInstitutionMemoRequest === null || requestParameters.putMedicalInstitutionMemoRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionMemoRequest','Required parameter requestParameters.putMedicalInstitutionMemoRequest was null or undefined when calling putMedicalInstitutionMemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/memo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionMemoRequestToJSON(requestParameters.putMedicalInstitutionMemoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionMemoFromJSON(jsonValue));
    }

    /**
     * 医療機関メモを更新する 
     * 医療機関メモの更新
     */
    async putMedicalInstitutionMemo(requestParameters: PutMedicalInstitutionMemoOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionMemo> {
        const response = await this.putMedicalInstitutionMemoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フェーズ更新
     */
    async putMedicalInstitutionPhaseRaw(requestParameters: PutMedicalInstitutionPhaseOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NullableMedicalInstitutionPhase>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling putMedicalInstitutionPhase.');
        }

        if (requestParameters.putMedicalInstitutionPhaseRequest === null || requestParameters.putMedicalInstitutionPhaseRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionPhaseRequest','Required parameter requestParameters.putMedicalInstitutionPhaseRequest was null or undefined when calling putMedicalInstitutionPhase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/phase`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionPhaseRequestToJSON(requestParameters.putMedicalInstitutionPhaseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NullableMedicalInstitutionPhaseFromJSON(jsonValue));
    }

    /**
     * フェーズ更新
     */
    async putMedicalInstitutionPhase(requestParameters: PutMedicalInstitutionPhaseOperationRequest, initOverrides?: RequestInit): Promise<NullableMedicalInstitutionPhase> {
        const response = await this.putMedicalInstitutionPhaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関特記事項を更新する 
     * 医療機関特記事項の更新
     */
    async putMedicalInstitutionSpecialNoteRaw(requestParameters: PutMedicalInstitutionSpecialNoteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionSpecialNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putMedicalInstitutionSpecialNote.');
        }

        if (requestParameters.putMedicalInstitutionSpecialNoteRequest === null || requestParameters.putMedicalInstitutionSpecialNoteRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionSpecialNoteRequest','Required parameter requestParameters.putMedicalInstitutionSpecialNoteRequest was null or undefined when calling putMedicalInstitutionSpecialNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/special_note`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionSpecialNoteRequestToJSON(requestParameters.putMedicalInstitutionSpecialNoteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionSpecialNoteFromJSON(jsonValue));
    }

    /**
     * 医療機関特記事項を更新する 
     * 医療機関特記事項の更新
     */
    async putMedicalInstitutionSpecialNote(requestParameters: PutMedicalInstitutionSpecialNoteOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionSpecialNote> {
        const response = await this.putMedicalInstitutionSpecialNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * タグ一覧の更新
     */
    async putMedicalInstitutionTagsRaw(requestParameters: PutMedicalInstitutionTagsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutMedicalInstitutionTagsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putMedicalInstitutionTags.');
        }

        if (requestParameters.putMedicalInstitutionTagsRequest === null || requestParameters.putMedicalInstitutionTagsRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionTagsRequest','Required parameter requestParameters.putMedicalInstitutionTagsRequest was null or undefined when calling putMedicalInstitutionTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/tags`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionTagsRequestToJSON(requestParameters.putMedicalInstitutionTagsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutMedicalInstitutionTagsResponseFromJSON(jsonValue));
    }

    /**
     * タグ一覧の更新
     */
    async putMedicalInstitutionTags(requestParameters: PutMedicalInstitutionTagsOperationRequest, initOverrides?: RequestInit): Promise<PutMedicalInstitutionTagsResponse> {
        const response = await this.putMedicalInstitutionTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
