/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutVocGroupsRequestVocGroups,
    PutVocGroupsRequestVocGroupsFromJSON,
    PutVocGroupsRequestVocGroupsFromJSONTyped,
    PutVocGroupsRequestVocGroupsToJSON,
} from './PutVocGroupsRequestVocGroups';

/**
 * 
 * @export
 * @interface PutVocGroupsRequest
 */
export interface PutVocGroupsRequest {
    /**
     * 
     * @type {Array<PutVocGroupsRequestVocGroups>}
     * @memberof PutVocGroupsRequest
     */
    vocGroups: Array<PutVocGroupsRequestVocGroups>;
}

export function PutVocGroupsRequestFromJSON(json: any): PutVocGroupsRequest {
    return PutVocGroupsRequestFromJSONTyped(json, false);
}

export function PutVocGroupsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutVocGroupsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vocGroups': ((json['voc_groups'] as Array<any>).map(PutVocGroupsRequestVocGroupsFromJSON)),
    };
}

export function PutVocGroupsRequestToJSON(value?: PutVocGroupsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voc_groups': ((value.vocGroups as Array<any>).map(PutVocGroupsRequestVocGroupsToJSON)),
    };
}

