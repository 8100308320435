/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VocStatusEnum {
    NotStarted = 'NOT_STARTED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    NoActionRequired = 'NO_ACTION_REQUIRED'
}

export function VocStatusEnumFromJSON(json: any): VocStatusEnum {
    return VocStatusEnumFromJSONTyped(json, false);
}

export function VocStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VocStatusEnum {
    return json as VocStatusEnum;
}

export function VocStatusEnumToJSON(value?: VocStatusEnum | null): any {
    return value as any;
}

