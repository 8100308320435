/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';
import {
    VocPriorityEnum,
    VocPriorityEnumFromJSON,
    VocPriorityEnumFromJSONTyped,
    VocPriorityEnumToJSON,
} from './VocPriorityEnum';
import {
    VocStatusEnum,
    VocStatusEnumFromJSON,
    VocStatusEnumFromJSONTyped,
    VocStatusEnumToJSON,
} from './VocStatusEnum';

/**
 * 連携先フィードバック
 * @export
 * @interface Voc
 */
export interface Voc {
    /**
     * 
     * @type {string}
     * @memberof Voc
     */
    id: string;
    /**
     * 聞き取り日
     * @type {string}
     * @memberof Voc
     */
    reportedDate: string;
    /**
     * 連携先医療機関ID
     * @type {number}
     * @memberof Voc
     */
    medicalInstitutionId: number;
    /**
     * タイトル
     * @type {string}
     * @memberof Voc
     */
    title: string;
    /**
     * 
     * @type {VocStatusEnum}
     * @memberof Voc
     */
    status: VocStatusEnum;
    /**
     * フィードバック詳細
     * @type {string}
     * @memberof Voc
     */
    detailText: string | null;
    /**
     * 対応内容
     * @type {string}
     * @memberof Voc
     */
    actionText: string | null;
    /**
     * 
     * @type {VocPriorityEnum}
     * @memberof Voc
     */
    priority: VocPriorityEnum;
    /**
     * 
     * @type {NullableStaff}
     * @memberof Voc
     */
    user: NullableStaff | null;
    /**
     * 更新日時
     * @type {string}
     * @memberof Voc
     */
    updatedAt: string;
}

export function VocFromJSON(json: any): Voc {
    return VocFromJSONTyped(json, false);
}

export function VocFromJSONTyped(json: any, ignoreDiscriminator: boolean): Voc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reportedDate': json['reported_date'],
        'medicalInstitutionId': json['medical_institution_id'],
        'title': json['title'],
        'status': VocStatusEnumFromJSON(json['status']),
        'detailText': json['detail_text'],
        'actionText': json['action_text'],
        'priority': VocPriorityEnumFromJSON(json['priority']),
        'user': NullableStaffFromJSON(json['user']),
        'updatedAt': json['updated_at'],
    };
}

export function VocToJSON(value?: Voc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reported_date': value.reportedDate,
        'medical_institution_id': value.medicalInstitutionId,
        'title': value.title,
        'status': VocStatusEnumToJSON(value.status),
        'detail_text': value.detailText,
        'action_text': value.actionText,
        'priority': VocPriorityEnumToJSON(value.priority),
        'user': NullableStaffToJSON(value.user),
        'updated_at': value.updatedAt,
    };
}

